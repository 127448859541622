@use '@angular/material' as mat;
@use './m3-theme' as m3Theme;
@use 'sass:map';

// Generated on https://material-foundation.github.io/material-theme-builder
// Overrides the generated material theme to stay true to the intended color, because the ng material theme builder does not support color match/stay true to color inputs
:root {
  --mat-sys-primary: #f5bf35;
  --mat-sys-on-primary: #3f2e00;
  --mat-sys-primary-container: #ffdf9c;
  --mat-sys-on-primary-container: #251a00;

  @media (prefers-color-scheme: dark) {
    --mat-sys-primary: #f5bf35;
    --mat-sys-on-primary: #3f2e00;
    --mat-sys-primary-container: #efba30;
    --mat-sys-on-primary-container: #423000;
  }
}
@include mat.typography-hierarchy(mat.define-typography(map.get(m3Theme.$theme-args, typography)));

html {
  color-scheme: light dark;
  --mat-sys-primary-95: #ffefd3;
  @include mat.theme(m3Theme.$theme-args);
}

.error-message {
  color: var(--mat-sys-error);
}

html,
body {
  height: 100%;
  margin: 0;
}

a {
  color: inherit;
}
