/* You can add global styles to this file, and also import other style files */
@use './variables' as vars;
@use '@angular/material' as mat;

body {
  background: var(--mat-sys-surface);
  color: var(--mat-sys-on-surface);

  //  custom changes to the material styles
  @include mat.toolbar-overrides(
    (
      standard-height: 64px,
      mobile-height: 64px,
    )
  );
  @include mat.list-overrides(
    (
      active-indicator-shape: 0,
    )
  );
  @include mat.fab-overrides(
    (
      small-container-shape: 50%,
      small-container-color: var(--mat-sys-primary),
    )
  );
  @include mat.chips-overrides(
    (
      container-shape-radius: 1rem,
      outline-width: 0,
    )
  );

  mat-sidenav {
    @include mat.sidenav-overrides(
      (
        container-divider-color: var(--mat-sys-outline),
        container-shape: 0,
        container-width: auto,
      )
    );
  }
}

.page-title {
  padding: 0 1rem;
}

.fab-bottom-right {
  position: fixed !important;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: 1000;
}

.fab-top-right {
  position: fixed !important;
  right: 1.5rem;
  top: 5rem;
  z-index: 1000;
}

.small-action-button {
  @include mat.fab-overrides(
    (
      small-foreground-color: var(--mat-sys-on-primary),
      small-container-elevation-shadow: none,
    )
  );
}

:root {
  --card-border-radius: 0.5rem;
  --max-screen-size: 1140px;
  --header-font-size: 4rem;
  --paragraph-font-size: 1.5rem;

  @media (max-width: vars.$large-breakpoint) {
    --header-font-size: 3rem;
    --paragraph-font-size: 1.35rem;
  }

  @media (max-width: vars.$tablet-breakpoint) {
    --header-font-size: 2rem;
    --paragraph-font-size: 1.2rem;
  }

  @include mat.card-overrides(
    (
      elevated-container-shape: var(--card-border-radius),
    )
  );
}

.dynamic-header {
  font-weight: bold !important;
  font-size: var(--header-font-size) !important;
  line-height: var(--header-font-size) !important;
}

.dynamic-paragraph {
  font-size: var(--paragraph-font-size) !important;
  line-height: var(--paragraph-font-size) !important;
}

// Google map infowindow styles

// top of the infowindow
.gm-style-iw-ch {
  padding-top: 0;
}

.gm-style-iw {
  background-color: var(--mat-sys-surface) !important;
  color: var(--mat-sys-on-surface) !important;
  padding: 0 !important;
}

.gm-style-iw-d {
  overflow: auto !important;
}

// Hide close button
.gm-style-iw button.gm-ui-hover-effect {
  display: none !important;
}

.generic-centered-card {
  max-width: 100%;
  width: 24rem;
  margin: 5rem auto;

  mat-card-header {
    justify-content: center;
  }

  mat-card-content {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  mat-card-actions {
    padding: 1rem;
  }

  button {
    width: 100%;
    height: 3rem;
    font-size: 1.25rem;
    @include mat.button-overrides(
      (
        outlined-outline-color: var(--mat-sys-primary),
        outlined-outline-width: 2px,
      )
    );
  }
}

.category-chip {
  .mat-mdc-chip-action-label {
    overflow: hidden;
  }
}

.image-library-dialog {
  @include mat.dialog-overrides(
    (
      container-min-width: 75vw,
    )
  );
}

// ensure the mat-select-search option is not opaque
.mat-mdc-option.mdc-list-item:has(span ngx-mat-select-search) {
  background-color: var(--mat-sys-surface-container);
}
